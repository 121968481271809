@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/tables';

// Color variables
$primary-color: #fa970f;
$primary-color-light: #ffb516;
$primary-color-lighter: #ffd43c;
$primary-color-dark: darken($primary-color, 5%);
$primary-color-darker: darken($primary-color, 35%);

$dark: #242a2b;
$gray: #687c80;
$gray-lighter: rgb(237, 239, 241);
$gray-light: darken($gray-lighter, 5%);
$gray-dark: darken($gray, 20%);
$light: white;

$transition-ease: cubic-bezier(0, 0.54, 0.58, 1);

// NORMALIZE
* {
	box-sizing: border-box;
}

html {
	font-family: 'Lato', sans-serif;
}

body {
	background-color: $gray-lighter;
	font-size: 16px;
	line-height: 1.4;
	margin: 0;
}

button,
input,
optgroup,
select,
textarea {
	font-family: 'Lato', sans-serif;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
}

a {
	text-decoration: none;
}

h1 {
	font-size: 28px;
}

h2 {
	font-size: 22px;
}

h3,
h4,
h5,
h6 {
	font-size: 18px;
}

// GLOBAL STYLES
#app {
	height: 100vh;
	padding-top: 56px;
}

.container {
	max-width: 1000px;
	width: 100%;
	margin: 0 auto;
}

.content {
	min-height: 100%;
	padding-bottom: 32px;
	margin-bottom: -32px;
	padding: 16px;
	transition: height 0.5s $transition-ease, padding 0.5s $transition-ease, opacity 0.5s $transition-ease;

	&.collapsed {
		// display: none;
		min-height: none;
		height: 0;
		overflow: hidden;
		padding-top: 60vh;
		margin-bottom: -100vh;
		transition: height 0.5s $transition-ease, padding 0.5s $transition-ease;
		opacity: 0;
	}

	> .results-list,
	> .content-block {
		margin-left: -16px;
		margin-right: -16px;
	}

	@media (min-width: 900px) {
		padding: 16px 0 32px;

		> .results-list,
		> .content-block {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

:focus {
	outline: 2px solid $primary-color;
}

// UTILS
.light-text {
	// color: lighten($gray, 10%);
	color: lighten($gray, 15%);
}

.gray-text {
	color: $gray;
}

.fa-sm {
	font-size: 0.75em;
}

.fa-xs {
	font-size: 0.3em;
}

.fas {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}

.fa-heading:before {
	content: '\f1dc';
}

.xsmall-only {
	@media (min-width: 401px) {
		display: none !important;
	}
}

.xsmall-hidden {
	@media (max-width: 400px) {
		display: none !important;
	}
}

.small-only {
	@media (min-width: 701px) {
		display: none !important;
	}
}

.small-hidden {
	@media (max-width: 700px) {
		display: none !important;
	}
}

@mixin btn() {
	border: none;
	background-color: transparent;
	border: none;
	border-radius: 0;
	// height: 32px;
	height: 40px;
	color: $dark;
	padding: 0 8px;
	cursor: pointer;

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&:hover {
		background-color: $gray-lighter;
		transition: background-color 0.5s;
	}
}

// HEADER
.app-header {
	background-color: $dark;
	height: 56px;
	padding: 8px;
	position: fixed;
	display: flex;
	justify-content: center;
	flex-direction: row;
	width: 100%;
	top: 0;
	z-index: 9500;
	transition: height 0.5s $transition-ease, background-color 0.5s $transition-ease;
	overflow-y: visible;

	.container {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.left-container,
	.right-container {
		flex: 0 0;
		display: flex;
		white-space: nowrap;
	}

	.right-container {
		justify-content: flex-end;

		@media (min-width: 1300px) {
			margin-left: 140px;
		}
	}

	.label-button-group {
		justify-content: start;
	}

	.searchbar {
		position: relative;

		.fa {
			position: absolute;
			margin: 10px 16px;
			font-size: 20px;
			color: $dark;
			top: 0;
		}

		.fa.fa-refresh {
			position: absolute;
			margin: 10px 16px;
			right: 0;
			top: 0;
			font-size: 20px;
			color: $primary-color !important;
		}

		.fa-search {
			position: absolute;
			margin: 10px 16px;
			right: 0;
			top: 0;
			font-size: 20px;
			color: $primary-color !important;
		}

		input {
			display: block;
			width: 100%;
			height: 40px;
			//background-color: transparentize($light, 0.9);
			border: none;
			border-radius: 4px;
			padding: 0 16px;
			transition: background-color 0.5s;

			&:hover {
				background-color: transparentize($light, 0.8);
			}

			&:focus {
				background-color: transparentize($light, 0.2);
			}
		}

		@media screen and (max-width: 700px) {
			input {
				&:focus {
					padding: 0 16px;
				}
			}

			input:focus + .fa {
				display: none;
			}
		}
	}

	.logo {
		display: none;
		margin: 32px auto;
		color: $gray-dark;
		text-align: center;

		img {
			margin-top: 32px;
		}

		a {
			display: block;
		}
	}

	.span-menu-expedientes {
		display: none;
	}

	&.expanded {
		height: 100vh;
		background-color: transparent;
		overflow-y: auto;
		align-items: flex-start;

		.span-menu-expedientes {
			display: block;
		}

		.searchbar {
			margin-bottom: 16px;

			.fa {
				color: lighten($gray, 20%);
				margin: 14px 16px;
			}

			input {
				background-color: $light;
				border: 1px solid $gray-light;
				height: 48px;
			}
		}

		.logo {
			display: block;
		}

		.container {
			min-height: 100%;
			height: auto;
		}

		.header-button {
			color: $gray;

			&:hover {
				color: $gray-dark;
				background-color: $light;
			}
		}

		.right-container {
			margin-left: 0px;

			@media (min-width: 700px) {
				margin-left: 0;
			}
		}

		.left-container {
			margin-left: 0;
			margin-right: 40px;

			@media (min-width: 700px) {
				margin-left: 0;
			}
		}

		.apps {
			margin-left: 0;
		}
	}

	.apps {
		margin-left: -16px;

		@media (min-width: 700px) {
			margin-left: 0;
		}
	}
}

.header-button {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	padding: 0;
	background-color: transparent;
	border: none;
	color: transparentize($light, 0.5);
	font-size: 24px;
	transition: background-color 1s, color 0.5s;

	&:hover {
		color: $light;
		background-color: transparentize($light, 0.9);
		cursor: pointer;
	}
}

.span-menu-expedientes {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 16px;
	left: 56px;
	position: absolute;
	top: 19px;
}

// BREADCRUMBS
.breadcrumbs {
	margin-bottom: 8px;
	color: $gray;

	a {
		display: inline-block;
		padding: 8px;
		color: $gray;
		text-decoration: none;

		&:hover {
			background-color: $gray-light;
		}
	}

	span:last-child {
		display: inline-block;
		padding: 8px;
	}
}

// LABELS, INPUTS N' VALUES

.label {
	color: $gray;
	line-height: 1;
	display: block;

	&:not(h1):not(h2):not(h3):not(h4) {
		font-size: 14px;
		font-weight: normal;
	}

	.opt {
		color: $gray;
		margin-left: 8px;
		font-style: italic;
	}

	h2,
	h3 {
		margin: 0;
	}

	&.inline {
		display: inline-block;
		margin-right: 8px;
	}
}

.label-inline {
	color: $gray;
	line-height: 1;

	&:not(h1):not(h2):not(h3):not(h4) {
		font-size: 14px;
		font-weight: normal;
	}
}

.label-button-group {
	display: grid !important;
	align-items: center;
	grid-template-columns: auto max-content;
	min-height: 40px;

	.item-count {
		font-size: 14px;
		font-weight: normal;
		display: block;
		margin-top: 8px;

		@media (min-width: 700px) {
			margin-top: -8px;
			display: inline;
			margin-left: 16px;
		}
	}

	.btn-left,
	.btn-right {
		@include btn();
		border: none;
		height: 40px;
		margin-top: -8px;
		margin-bottom: -8px;
		margin-left: -28px;
		background-color: transparent;
		width: 28px;
		text-align: center;

		@media (min-width: 700px) {
			width: 40px;
			margin-left: -40px;
		}

		.fa {
			font-size: 20px;
		}

		&:hover {
			background-color: transparent;

			.fa {
				color: $gray-dark;
			}
		}
	}
}

.value {
	font-size: 22px;
	font-weight: 700;
	line-height: 1.4;
	margin-top: 0;
	margin-bottom: 0;

	@media (min-width: 700px) {
		font-size: 28px;
	}
}

input[type='text'],
input[type='date'],
input[type='number'],
input[type='tel'],
.like-input,
select,
textarea {
	display: block;
	width: 100%;
	height: 40px;
	background-color: transparentize($light, 0.9);
	border: 1px solid $gray;
	border-radius: 2px;
	padding: 8px 16px;
	color: $gray-dark;

	&:hover {
		background-color: transparentize($light, 0.8);
	}

	&:focus {
		color: $dark;
		background-color: transparentize($light, 0.2);
	}
}

textarea {
	height: auto;
	line-height: 1.4;
}

input[type='radio'] {
	height: 14px;
	width: 18px;
	margin-right: 8px;
	cursor: pointer;

	&:focus {
		outline-offset: 4px;
	}
}

input[type='radio'] + .label {
	display: inline-block;
	color: $gray-dark;
	font-size: 16px;
	cursor: pointer;
	position: relative;
	//top: 1px;
}

input[type='checkbox'] + .label {
	display: inline-block;
	color: $gray-dark;
	font-size: 16px;
	cursor: pointer;
	position: relative;
	top: 2px;
}

textarea[readonly]:not(.ant-input),
textarea[readonly]:hover:not(.ant-input),
textarea[readonly]:focus:not(.ant-input),
input[readonly]:not(.ant-input),
input[readonly]:hover:not(.ant-input),
input[readonly]:focus:not(.ant-input) {
	color: $gray;
	background-color: $gray-lighter;
	cursor: not-allowed;
}

.input-with-icon {
	position: relative;

	i {
		position: absolute;
		right: 2px;
		top: 2px;
		font-size: 22px;
		padding: 8px 12px;
	}
}

.input-table {
	.input-table-header {
		display: none;
		margin-left: -25px;
	}

	.input-row {
		margin-bottom: 8px;
		gap: 5px;
	}

	.row-label {
		margin-bottom: 0;
		margin-top: 8px;
		font-weight: bold !important;
		color: $gray;
		flex: 0.5;
	}

	.input-group:not(:last-child) {
		margin-bottom: 16px;
	}

	@media (min-width: 510px) {
		.input-group:not(:last-child) {
			margin-bottom: 0;
		}

		.row-label {
			margin-bottom: 32px;
		}

		.input-row {
			.label {
				display: none;
			}
		}

		.input-table-header {
			display: flex;
			margin-bottom: 0;

			.label {
				display: block;
			}
		}
	}
}

// SIDE MENU
.side-menu-wrapper {
	position: absolute;
	right: 8px;
	top: 36px;

	.side-menu {
		margin-top: 20px;
		box-shadow: 0px 0px 0px 1px lightgrey;
		width: 320px;
		background-color: $light;
		position: relative;
		min-height: 40px;
		max-height: calc(100vh - 120px);
		overflow-y: auto;

		@media (min-width: 700px) {
			max-height: calc(100vh - 64px);
		}

		.menu-section {
			list-style: none;
			padding: 16px 0;
			margin: 0;

			// &:not(:last-of-type) {
			// 	border-bottom: solid 1px $gray-light;
			// }

			li {
				&.list-title {
					padding: 8px 32px;
					color: #687c80;
				}

				button,
				.menu-button {
					@include btn();
					height: 40px;
					width: 100%;
					text-align: left;
					padding: 0 32px;
					display: flex;
					align-items: center;

					.fa {
						width: 40px;
						min-width: 40px;
						line-height: 1.4;
						flex: 0 0;
					}
				}

				&.username {
					padding: 8px 32px;
					height: 40px;
					color: $gray;
				}
			}
		}
	}
}

.side-menu-wrapper {
	.apps-menu {
		.menu-section {
			padding: 0;

			li {
				padding: 0;
				border-bottom: 1px solid $gray-lighter;

				button,
				.menu-button {
					padding: 16px 32px;
					height: auto;

					.title {
						font-size: 24px;
						padding-top: 6px;
						color: $gray;
					}

					&:hover {
						background-color: $gray-lighter;

						.title {
							color: $gray-dark;
						}
					}
				}
			}
		}
	}
}

//WIKI
// .side-menu-wrapper {
// 	.side-menu {
// 		&.wiki {
.wiki {
	width: auto;

	.title-with-button {
		display: grid;
		align-items: center;
		grid-template-columns: auto max-content;
		padding: 8px 8px 8px 16px;
		height: 48px;

		h2 {
			margin: 0;
			color: $gray;
		}

		.fa {
			font-size: 22px;
			line-height: 40px;
		}
	}

	nav {
		padding: 8px 16px 8px;

		.btn-default {
			margin-right: 8px;
			height: 32px;
		}

		.btn-primary {
			margin-right: 8px;
			height: 32px;
			padding: 6px 16px;
		}
	}

	.text-area-wrapper {
		display: flex;
		align-items: stretch;
		white-space: normal;

		.editor,
		.view {
			position: relative;
			width: 100%;
			max-width: calc(100vw - 16px);
			min-height: 400px;
		}

		.wiki-view-content {
			padding: 8px 16px;
			height: 100%;
			border: 1px solid #c8ccd0;
			border-left: none;
			border-right: none;
			overflow-x: hidden;
		}

		.react-mde {
			height: 100%;
			border-left: none;
			border-right: none;
			border-radius: 0;

			.react-mde-no-preview-layout {
				height: 100%;
				display: flex;
				flex-direction: column;

				.mde-text {
					flex: 1 0;

					.public-DraftEditor-content {
						max-height: calc(100vh - 216px);
						padding: 8px 16px;
					}
				}
			}
		}

		.editor + .view .wiki-view-content {
			border-left: 1px solid #c8ccd0;
		}
	}

	&.expanded {
		width: 100%;

		.text-area-wrapper {
			.wiki-view-content,
			.react-mde {
				max-width: 900px;
			}
		}
	}
}

// 	}
// }

.wiki-view-content {
	h1,
	h2,
	h3 {
		&:first-child {
			margin-top: 0;
		}
	}

	h1 {
		font-size: 1.8em;
	}

	h2 {
		font-size: 1.6em;
	}

	h3 {
		font-size: 1.4em;
	}

	code {
		background-color: $gray-light;
		padding: 8px 16px;
	}

	img {
		max-width: 100%;
	}
}

.content-block {
	.text-area-wrapper {
		margin-left: -32px;
		margin-right: -32px;

		.editor,
		.view {
			width: 420px;
			max-width: none;
			min-height: 400px;
			flex: 1 1 auto;
		}
	}
}

// BUTTONS

.btn {
	@include btn();
}

.btn-default {
	@include btn();
	background-color: $gray-light;
	color: $dark;

	&:hover {
		background-color: darken($gray-light, 5%);
	}
}

.btn-primary {
	@include btn();
	background-color: $primary-color;
	color: $light;

	.fa {
		color: $light;
	}

	&:hover {
		background-color: $primary-color-dark;
	}

	&:focus {
		outline-color: darken($primary-color-dark, 10%);
	}
}

// SEARCH RESULTS LISTS

.results-count {
	color: $gray;
	margin-right: 32px;
}

.results-list {
	padding: 0;
	list-style: none;

	.results-item {
		background-color: $light;
		position: relative;
		padding: 16px;
		border: 1px solid $gray-light;
		border-left: 4px solid transparent;
		&.results-item-selo-overflow-3 {
			padding-bottom: 60px;
			@media (max-width: 550px) {
				padding-bottom: 25px;
			}
		}
		&.results-item-selo-overflow-4 {
			//padding-bottom: 120px;
			@media (max-width: 550px) {
				padding-bottom: 70px;
			}
		}
		&.results-item-selo-overflow-5 {
			padding-bottom: 180px;
			@media (max-width: 550px) {
				padding-bottom: 115px;
			}
		}
		&.results-item-selo-overflow-6 {
			padding-bottom: 240px;
			@media (max-width: 550px) {
				padding-bottom: 160px;
			}
		}

		.results-anchor {
			margin: -16px;
			padding: 16px 32px;
			color: inherit;
			display: block;

			.input-group {
				margin-bottom: 0;

				.label {
					margin-bottom: 8px;
				}

				.value {
					line-height: 1;
				}

				&.copy-button .btn {
					position: relative;
					float: left;
					top: 9px;
				}
			}

			.box {
				margin-top: 16px;
			}
		}

		&:hover {
			border-left: 4px solid $primary-color;
		}
	}
}

.inner-list-item {
	// overflow: hidden;
	// text-overflow: ellipsis;
	max-width: 100%;

	.id {
		margin-right: 16px;
		font-weight: bold;
	}

	&.inner-list-item-input {
		display: inline-block;
		line-height: 40px;
	}

	&.truncated {
		max-width: calc(100% - 42px);
		display: inline-block;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		vertical-align: middle;
	}
}

.block-list {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding-left: 0;

	.block-list-item {
		display: block;
		flex: 0 1;
		background: $gray-lighter;
		padding: 8px 16px;
		margin-right: 8px;
		margin-top: 4px;

		.value {
			color: $dark;
			font-size: 16px !important;
			font-weight: normal;
		}

		.light-text {
			color: lighten($dark, 25%);
		}
	}
}

.pagination {
	display: block;
	margin: 32px;

	.page {
		display: inline-block;
		color: $gray-dark;
		padding: 8px 16px;
		text-decoration: none;
		cursor: pointer;
		user-select: none;

		&.disabled {
			opacity: 0.5;
		}

		&.active {
			background-color: $primary-color;
			color: white;
			border-radius: 5px;
		}

		&:hover:not(.active) {
			background-color: $gray-lighter;
			border-radius: 5px;
		}
	}
}

.floating-list-wrapper {
	position: relative;
}

.input-floating-list {
	display: block;
	position: absolute;
	width: 1000px;
	max-width: 100%;
	border: 1px solid $gray-dark;
	background-color: $light;
	top: 100%;
	max-height: 240px;
	overflow-y: auto;
	z-index: 10;

	.results-count {
		margin: 8px 16px;
	}

	.results-list {
		margin-top: 0;
		margin-bottom: 0;

		.results-item {
			position: relative;
			padding-top: 8px;
			padding-bottom: 8px;
			&.results-item-selo-overflow-3 {
				padding-bottom: 60px;
				@media (max-width: 550px) {
					padding-bottom: 25px;
				}
			}
			&.results-item-selo-overflow-4 {
				//padding-bottom: 120px;
				@media (max-width: 550px) {
					padding-bottom: 70px;
				}
			}
			&.results-item-selo-overflow-5 {
				padding-bottom: 180px;
				@media (max-width: 550px) {
					padding-bottom: 115px;
				}
			}
			&.results-item-selo-overflow-6 {
				padding-bottom: 240px;
				@media (max-width: 550px) {
					padding-bottom: 160px;
				}
			}

			&:hover,
			.results-anchor:hover,
			&.fake-focus,
			.results-anchor.fakefocus {
				background-color: $gray-lighter;
			}
		}
	}
}

// OBJECT TITLES

.title-with-id {
	.id,
	.codigo {
		margin-right: 16px;
		font-weight: bold;
	}
}

.title-with-button {
	// display: grid;
	// grid-template-columns: auto max-content;
	min-height: 40px;

	button {
		@include btn();
		border: none;
		background: transparent;
		height: 40px;
		width: 40px;
		margin-top: -8px;
		margin-bottom: -8px;

		.fa {
			color: $gray;
		}

		&:hover {
			background: $gray-lighter;
		}
	}
}

// LISTS

.embed-list {
	list-style: none;
	padding: 0;
	margin-top: 4px;

	.embed-list-item {
		line-height: 1.4;
		height: auto;

		.grid {
			background: $gray-lighter;
			padding: 8px 16px;
			display: grid;
			grid-column-gap: 16px;
			grid-template-columns: 16px auto;

			&:hover {
				background-color: $gray-light;
				transition: background-color 0.5s;
			}
		}

		.input-row {
			background: $gray-lighter;
			padding: 8px 8px 8px 16px;
			margin: 8px -16px;

			input {
				background-color: white;
			}
		}

		.embed-complement {
			margin-left: -16px;
			margin-right: -16px;
			margin-bottom: -16px;
			padding-left: 16px;
			padding-right: 16px;

			// padding-top: 16px;
			&:not(:first-child) {
				border-top: 1px solid transparentize($gray, 0.8);
				margin-top: 16px;
			}

			h3 {
				margin-top: 0;
			}

			.grid {
				background-color: transparent;
			}

			.complement {
				padding-left: 16px;
				padding-right: 16px;
				padding-top: 0;
			}
		}

		.expanded {
			margin: 0 -16px;
			background: $gray-light;

			.inner-list-item {
				.item-with-date,
				.item-with-status-date {
					span:first-child {
						font-weight: bold;
					}
				}
			}

			.item-with-date {
				font-weight: bold;
			}
		}

		&:not(:last-child) {
			border-bottom: solid 1px $light;

			.complement {
				margin-bottom: 8px;
			}
		}

		&:not(:first-child) {
			.expanded {
				margin-top: 8px;
			}
		}

		.complement {
			font-size: 14px;
			white-space: pre-wrap;
			background: lightgray;
			margin: 0 -16px;
			padding: 2px 32px 16px;
			background: $gray-lighter;
			position: relative;

			.label {
				margin-bottom: 8px;
				margin-top: 16px;
			}

			p {
				margin-top: 0;
				margin-bottom: 0px;
			}
		}

		.fa {
			color: $gray;
			line-height: 24px;
		}

		.btn-primary .fa {
			color: white;
			line-height: inherit;
		}

		.btn-left,
		.btn-right {
			@include btn();
			border: none;
			height: 40px;
			margin-top: -8px;
			margin-bottom: -8px;
			margin-left: -16px;
			background-color: transparent;
			width: 40px;
			text-align: center;

			.fa {
				font-size: 20px;
			}

			&:hover {
				background-color: transparent;

				.fa {
					color: $gray-dark;
				}
			}
		}

		.btn-right {
			margin-left: 0;

			&:last-of-type {
				margin-right: -16px;
			}
		}

		.inner-list-item {
			.item-with-date {
				display: grid;
				grid-template-rows: auto max-content;

				.date {
					color: $gray;
				}

				@media (min-width: 700px) {
					display: grid;
					grid-column-gap: 16px;
					grid-template-columns: auto max-content;
				}
			}

			.item-with-status-date {
				display: grid;
				grid-template-rows: auto auto auto;

				.status {
					color: $gray;
				}

				.date {
					color: $gray;
				}

				@media (min-width: 700px) {
					display: grid;
					grid-column-gap: 16px;
					grid-template-columns: auto 110px max-content;
				}
			}
		}
	}

	&.clear {
		.embed-list-item {
			.grid {
				background: transparent;
				padding: 0;
				display: block;

				&:hover {
					background-color: transparent;
				}
			}
		}

		.fa {
			line-height: 19px;
		}
	}
}

.empty-embed-list {
	color: $gray;
	background-color: $gray-lighter;
	padding: 8px 32px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.msg {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.address-list {
	.embed-list {
		.embed-list-item {
			.grid {
				display: inline-block;
				background: transparent;
				padding: 0;

				&:hover {
					background: transparent !important;
				}

				.fa {
					margin-right: 8px;
				}

				.inner-list-item {
					display: inline-block;
				}
			}
		}
	}
}

// TABLES
.area {
	white-space: nowrap;
}

.area:after {
	content: ' m²';
}

.table {
	width: 100%;
	border-spacing: 0;
}

.table-responsive {
	display: block;
	min-width: calc(100% + 32px);
	margin-left: -16px;
	margin-right: -16px;
	overflow-x: auto;
	margin-top: 8px;
}

.area-table {
	tr:nth-child(even) {
		background-color: transparentize($gray, 0.9);
	}

	thead tr th {
		border-bottom: 1px solid transparentize($gray, 0.8);
	}

	td,
	th {
		&:not(:first-child) {
			text-align: right;
		}

		padding: 4px 16px;
		white-space: nowrap;
	}

	td:not(:first-child) {
		font-style: italic;
	}

	th {
		color: $gray;
	}
}

// PAGE TEMPLATES

.content-block {
	background-color: $light;
	padding: 16px 32px;
	display: block;
	margin-bottom: 32px;
	border: solid 1px $gray-light;
}

h2,
h3,
h4 {
	color: $gray;
	margin: 32px 0 16px;
}

.copy-button {
	text-align: right;
	padding-right: 12px;

	.btn {
		font-size: 14px;
		height: 40px;
		display: inline-block;
		padding: 11.5px 8px;
		font-weight: normal;
	}
}

.header-group {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	border-bottom: solid 1px $gray-light;

	padding: 16px 32px;
	margin: -16px -32px 0;

	h1 {
		margin: 0;
		flex-grow: 1;

		> * {
			flex-shrink: 0;
		}

		> :nth-child(2) {
			flex-shrink: 1;
		}
	}

	.input-group {
		margin-bottom: 0;

		.value {
			line-height: 1;
		}
	}

	.action {
		display: flex;
		align-items: center;
		font-size: 14px;
	}
}

.header-link {
	color: $primary-color-dark;
	font-size: 1rem;
	font-weight: normal;
}

.form-group {
	max-width: 600px;
	margin: 50px auto;
	position: relative;
}

.form-section {
	background: $gray-lighter;
	margin: 0px -16px 16px;
	padding: 8px 16px;

	@media (min-width: 700px) {
		margin: 0px -40px 16px;
		padding: 8px 40px;
	}

	input,
	textarea {
		background: $light;
	}
}

.form-control {
	background-color: $light !important;
	&:disabled,
	&.readOnly {
		background-color: $gray-lighter !important;
	}
}

.input-row {
	margin-right: -16px;
	column-gap: 20px;
	margin-right: 0px;

	&.no-space {
		margin-right: -8px;
	}

	@media (min-width: 510px) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.input-group {
			margin-bottom: 0;
		}
	}

	> * {
		flex: 1 0;
		//margin-right: 30px;
	}

	&.no-space > * {
		margin-right: 8px;
	}
}

.input-group {
	margin-bottom: 32px;
	position: relative;

	.label {
		margin-bottom: 8px;
	}

	[type='checkbox'] + label {
		margin-left: 8px;
		display: inline-block;
	}
}

.submit-group {
	border-top: solid 1px $gray-light;
	padding: 16px 16px 16px 32px;
	margin: 0 -32px -16px;
	display: flex;

	button {
		padding: 0 48px;
		margin-right: 16px;
		white-space: nowrap;

		@media (max-width: 701px) {
			padding: 0 16px;
		}
	}
}
.submit-group-small {
	border-top: solid 1px $gray-light;
	padding: 16px 16px 16px 32px;
	margin: 0 -32px -16px;
	display: flex;
	width: 100%;
	justify-content: flex-end;

	button {
		padding: 0 16px;
		margin-right: 16px;
		white-space: nowrap;

		@media (max-width: 701px) {
			padding: 0 16px;
		}
	}
}

.lock-content {
	background-color: $gray-light;
	margin-left: -16px;
	margin-right: -16px;
	margin-bottom: 32px;
	padding: 16px;
	position: relative;

	> * {
		opacity: 0.5;
	}

	.lock-message {
		opacity: 1;
		text-align: center;
		width: calc(100% - 32px);
		color: $gray-dark;
		background: transparentize($gray-light, 0.5);
		position: absolute;
		top: 50%;
		left: calc(50% + 32px);
		z-index: 100;
		transform: translate(-50%, -50%);

		.fa {
			font-size: 140%;
			margin-bottom: 8px;
			display: block;
		}

		div {
			margin-top: 16px;

			.btn-primary,
			.btn-default {
				// background-color: $gray-light;
				padding-left: 32px;
				padding-right: 32px;
				background-color: darken($gray-light, 5%);
				margin-right: 16px;
			}

			.btn-primary {
				background-color: $gray;
			}
		}
	}
}

// ERRORS AND WARNINGS

.lock-message {
	background-color: $gray;
	margin: 0 -32px -16px;
	padding: 21px 32px 29px 32px;

	p {
		margin: 0;
		color: $light;
		font-size: 14px;
	}

	.head {
		font-size: 16px;
		font-weight: bold;
	}
}

.lock-message + .form-group .selos {
	top: -45px;
}

ul.error-block {
	padding-left: 0;
	list-style: none;
}

.error-block {
	margin-bottom: 16px;
	margin-top: 8px;
}

.error-message {
	color: darken(red, 25%);

	.fa {
		color: darken(red, 15%);
	}
}

.warning-message {
	color: adjust-hue(saturate(darken(yellow, 30%), 20%), calc(-15% / 1%));

	.fa {
		color: adjust-hue(saturate(darken(yellow, 10%), 50%), calc(-10% / 1%));
	}
}

.help-message {
	padding-left: 5px;
	font-size: 16px;
	margin-top: -8px;
	margin-bottom: 16px;
	color: $gray;
}

// ERROR SCREEN
.error-screen {
	display: flex;
	height: 100%;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	color: $gray;

	> * {
		width: 300px;
		text-align: left;
	}

	.error-header {
		font-size: 80px;
		margin-bottom: 64px;

		.error-label {
			display: block;
			font-size: 32px;
			line-height: 32px;
		}
	}

	.logo {
		margin-top: 64px;

		img {
			width: 196px;
		}
	}
}

// LOADER

.loader-wrapper {
	background-color: rgba(255, 255, 255, 0.7);
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 90000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	span {
		display: inline-block;
		padding: 4px 16px;
		// background-color: rgba(255, 255, 255, 0.5);
	}

	.loader {
		border-radius: 50%;
		height: 42px;
		width: 42px;
		margin-top: 16px;
		animation: spin 1s infinite linear;
		border: 4px solid $primary-color;
		border-top-color: transparent;
	}

	&.block-loader {
		position: relative;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

// MODAL
.modal-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: transparentize(black, 0.4);
	z-index: 100;
	display: flex;
	justify-content: space-around;
	align-items: center;

	&.modal-wrapper-sb {
		.modal-title {
			font-weight: bold;
			font-size: 1.2em;
			padding: 10px;
		}
		.fa-check:before {
			display: none;
		}
		.fa-exclamation-circle:before {
			display: none;
		}
		.titulo-slot-add-doc {
			padding: 10px;
		}
		input.form-control {
			display: block;
			padding: 10px;
			margin: 10px;
			border: 2px solid #b9b7b7;
			width: 90%;
		}
	}

	p {
		font-size: 18px;
	}

	.modal {
		background: $gray-lighter;
		width: calc(100% - 32px);
		width: auto;
		max-width: 420px;
		padding: 16px 32px;

		&.no-width {
			width: none;
			min-width: none;
			max-width: none;
		}
		&.container-width {
			max-width: calc(860px * 0.9);
		}
	}

	&.min70 {
		.modal-content {
			min-width: 70%;
		}
	}
}

.modal-content {
	background-color: #fff !important;
	padding: 30px 50px;
}

// MESSAGE DOCK
.message-dock {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 101;

	.container {
		list-style: none;
		padding: 0;
	}

	.message-item {
		position: relative;
		background-color: $gray;
		color: $light;
		border-radius: 4px;
		padding: 16px;
		padding-right: 48px;
		margin-left: 16px;
		margin-right: 16px;
		margin-bottom: 8px;

		&.error {
			color: #721c24;
			background-color: #f8d7da;
			border-color: #f5c6cb;
		}

		&.warning {
			color: #856404;
			background-color: #fff3cd;
			border-color: #ffeeba;
		}

		&.info {
			color: #004085;
			background-color: #cce5ff;
			border-color: #b8daff;
		}

		.btn {
			position: absolute;
			top: 8px;
			right: 8px;
			width: 40px;
			height: 40px;
			color: $light;
			opacity: 0.8;

			&:hover {
				background-color: transparentize($light, 0.8);
			}

			&.error {
				color: #721c24;
			}

			&.warning {
				color: #856404;
			}

			&.info {
				color: #004085;
			}
		}
	}
}

// COMPONENTS OVERRIDES

.rc-calendar {
	font-family: inherit;

	.rc-calendar-selected-date {
		.rc-calendar-date {
			background: $primary-color;
			color: $light;
		}
	}

	.rc-calendar-today {
		.rc-calendar-date {
			border: 1px solid $primary-color;
		}
	}

	.rc-calendar-date:hover {
		background: lighten($primary-color-lighter, 15%);
		cursor: pointer;
	}

	.rc-calendar-today-btn,
	.rc-calendar-ok-btn,
	.rc-calendar-time-picker-btn {
		color: $primary-color-dark;
	}
}

.btn-calendar {
	position: absolute;
	top: 0;
	right: 4px;
	color: $gray;
	height: 32px;
	margin: 26px 0px;
	width: 32px;
	.btn-copy {
		position: absolute;
		top: 0;
		right: 0;
		width: 85px;
		height: 32px;
		i {
			margin-right: 5px;
		}
	}
}

// ANIMATIONS

.fly-down-enter {
	opacity: 0;
	transform: translateY(-20px) scaleY(0.5);
}

.fly-down-enter-active {
	opacity: 1;
	transform: translateY(0) scaleY(1);
	transform-origin: top;
	transition: all 0.5s ease-out;
}

.fly-down-enter-done {
	opacity: 1;
	transform: translateY(0) scaleY(1);
}

.fly-down-exit-active {
	opacity: 0;
	transform: translateY(-20px) scaleY(0.5);
	transform-origin: top;
	transition: all 0.3s ease-out;
}

.fade-enter {
	opacity: 0.01;
	margin-top: -60px;
}

.fade-enter-active {
	opacity: 1;
	margin-top: 0px;
	transition: opacity 300ms ease-in, margin-top 300ms ease-in;
}

.fade-exit {
	opacity: 1;
	margin-top: 0px;
}

.fade-exit-active {
	opacity: 0.01;
	margin-top: -60px;
	transition: opacity 300ms ease-in, margin-top 300ms ease-in;
}

//HEADER HOMOLOGAÇÃO
.is-hom {
	background-color: darkred !important;

	&.expanded {
		background-color: transparent !important;
		border: 4px red solid;

		&:before {
			content: 'ambiente de homologação';
			color: red;
			position: absolute;
		}
	}
}

.minimap-container {
	height: 400px;
	overflow: auto;

	.minimap {
		position: absolute;
		float: none;
		margin: 0;
		top: 40px;
		right: -64px;
		border: none;
		background-color: lighten($gray, 40%);

		.minimap-viewport {
			background-color: transparentize($gray-dark, 0.9);
		}
	}

	> div {
		min-height: 400px;
	}
}

.diff-container {
	display: flex;
	width: 100%;
	font-size: 12px;
	min-height: 400px;
	padding-right: 20px;

	.diff-side {
		flex: 1 1 50%;
		overflow-x: auto;
		min-height: 400px;
	}

	.diff-obj-wrapper {
		display: inline-block;
		min-width: 100%;
		min-height: 400px;
	}

	.diff-obj {
		background-color: #edeff1;
		margin: 0;
		min-height: 400px;
	}
}

.msg-digital {
	background: $primary-color-lighter;
	padding: 12px 25px;
	font-size: 13.6px;
	font-style: italic;
	border-left: 1px solid #dfe2e6;
	border-top: 1px solid #dfe2e6;
	border-right: 1px solid #dfe2e6;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	text-align: center;
	color: $dark !important;

	&:hover {
		cursor: pointer;
		background: darken($primary-color-lighter, 10%);
	}
}

.bloco-digital {
	.header-group {
		position: relative;
		padding-top: 70px;
	}
}

.processoB {
	img {
		position: relative;
		top: 15px;
	}
}
.listaDocsEu {
	color: $dark !important;
	text-transform: lowercase;

	&::first-letter {
		text-transform: capitalize;
	}
}
.listaDocsEuEtapa {
	a {
		color: black;
		margin-bottom: 2px;
		display: block;
		i {
			margin-left: 10px !important;
		}
		&:hover {
			color: $primary-color !important;
			i {
				color: $primary-color !important;
			}
		}
	}
}

.grid.situacaoProcesso {
	display: block !important;
}

.wrapper-class-name-readOnly {
	height: auto;
}

.toolbar-class-name-readonly {
	display: none;
}

.editor-class-name-readOnly {
	margin-top: -15px;
	padding: 0px 2px;
	// font-style: italic;
	width: 100%;
}

.wrapper-class-name {
	height: auto;
	display: block;
	border: 1px solid #687c80;
}

.toolbar-class-name {
	display: flex;
}

.editor-class-name {
	width: 100%;
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 2px;
	padding: 8px 16px;
	color: #3a4548;
	height: 20em;
	overflow: auto;
}

.toolbar-search {
	display: grid;
	grid-template-columns: 2fr 8fr;
	grid-column-gap: 10px;

	&.sem-botao {
		grid-template-columns: 1fr;
	}
}

.searchbar {
	position: relative;

	.fa {
		position: absolute;
		margin: 10px 16px;
		font-size: 20px;
		color: $dark;
		top: 0;
	}

	.fa-search,
	.fa-hourglass-end {
		position: absolute;
		margin: 10px 16px;
		right: 0;
		top: 0;
		font-size: 20px;
		color: $primary-color !important;
	}

	input {
		display: block;
		width: 100%;
		height: 40px;
		background-color: transparentize($light, 0.7);
		border: 1px solid;
		border-radius: 4px;
		padding: 0 16px 0 16px;
		transition: background-color 0.5s;
		color: $dark;

		&::placeholder {
			color: lighten($dark, 50%);
		}

		&:hover {
			background-color: transparentize($light, 0.8);
		}

		&:focus {
			background-color: transparentize($light, 0.2);
		}
	}

	@media screen and (max-width: 700px) {
		input {
			&:focus {
				padding: 0 16px;
			}
		}

		input:focus + .fa {
			display: none;
		}
	}

	.clear-button {
		background: transparent;
		border: none;
		padding: 8px;
		position: absolute;
		right: 38px;
		top: 2px;

		.fa {
			position: relative;
			margin: 0;
			font-size: 15px;
			color: #aaaaaa;
		}
	}
}

.cad-predial {
	.current-path {
		padding: 20px 0px;
		.title {
			font-size: 16pt;
			margin-right: 20px;
		}

		.separator {
			margin: 0px 10px;
		}

		.folder {
			color: $primary-color;
			font-weight: 700;
		}
	}

	.folder {
		text-decoration: underline;
		cursor: pointer;
	}

	.file {
		cursor: pointer;
	}

	ul {
		list-style: none;
		overflow-y: auto;
		padding-left: initial;

		&.embedded {
			max-height: 10rem;
		}

		li {
			padding: 5px;
		}
	}
}

.btn-link {
	color: $gray;
	height: auto;

	&:visited {
		color: $gray;
	}

	&:hover {
		color: $primary-color;
		background-color: transparent;
	}
}

.d-flex {
	display: flex;
}

.flex {
	display: flex;
	width: 100%;
}

.gap-10 {
	gap: 10px;
}

.flex-1 {
	flex: 1;
}

.flex-nowrap {
	flex-wrap: nowrap;
}

.jc-space-between {
	justify-content: space-between;
}

.hr-light-gray {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #d5d5d5;
	margin: 1em 0;
	padding: 0;
}

.ai-c {
	align-items: center;
}

.container-lca {
	.linha-lca {
		display: flex;
		gap: 10px;
		padding-top: 4px;
		padding-bottom: 4px;

		&:last-child {
			margin-bottom: 20px;
		}

		.rotulo {
			font-size: 0.8rem;
			min-width: 25%;
		}

		.valor {
			font-size: 0.8rem;
			font-weight: 700;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}

.embed-list-item {
	.button-group {
		display: inline-flex;
		gap: 5px;
		justify-content: flex-end;
		width: 110px;
		text-align: right;

		.btn-right {
			width: 30px !important;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}

.content-block {
	.form-group {
		max-width: 880px;
		position: relative;
	}
}

.btn-debug {
	@include btn();
	background-color: yellow;
	color: red;

	&:hover {
		background-color: darken(yellow, 5%);
	}

	.fa {
		font-size: 1.2em;
		font-weight: normal;
		margin-left: 5px;
	}
}

.margin-bottom {
	margin-bottom: 20px;
}

.radio-embeddable {
	width: 100%;
	padding: 0px;

	.radio-embeddable-options {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		gap: 20px;

		&.stacked {
			flex-direction: column;
		}

		.radio-embeddable-option {
			white-space: nowrap;
			vertical-align: middle;
			position: relative;

			.label {
				top: -2px;
			}
		}
	}
}

.dte {
	display: grid;
	grid-template-columns: 100px 100px 100px 1fr;
	gap: 10px;
	justify-content: space-between;
	align-items: baseline;
	margin-bottom: 25px;
}

.input-like {
	display: flex;
	flex: 1;
	border: 1px solid $gray;
	padding: 8px 15px;
	justify-content: right;
	position: relative;
	background-color: $gray-lighter;
	color: gray;

	&.plano-diretor {
		border-color: $gray-lighter;
	}

	label {
		position: absolute;
		top: -20px;
		right: 14px;
		font-size: 0.9rem;
		color: $gray;
	}

	&.left {
		justify-content: left;
		label {
			right: auto;
			left: 13px;
		}
	}
}

.select-padrao {
	appearance: none;
	background-color: #fafafa;
	height: 45px;
	width: 100%;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
	background-position: 100%;
	background-repeat: no-repeat;
	border: 1px solid #ccc;
	padding: 0.5rem;
	border-radius: 0;
}

.table-striped {
	th,
	td {
		vertical-align: middle;
		border: none;
	}
	tbody tr:nth-of-type(odd) {
		background-color: $gray-lighter;
	}
	tbody tr:nth-of-type(even) {
		background-color: $gray-light;
	}
}

.table-striped-2-lines {
	th,
	td {
		vertical-align: middle;
		border: none;
	}
	tbody tr:nth-of-type(4n+1),
	tbody tr:nth-of-type(4n+2) {
		background-color: $gray-lighter; /* Cor para as linhas ímpares */
	}
	tbody tr:nth-of-type(4n+3),
	tbody tr:nth-of-type(4n+4) {
		background-color: $gray-light; /* Cor para as linhas pares */
	}
}

.table-consulta-saldo {
	background-color: $gray-lighter;
	border: none;
	border-spacing: 0;
	width: 100%;
	tbody {
		tr {
			td {
				white-space: nowrap;
				padding: 10px;

				&.acoes {
					display: flex;
					justify-content: center;
					align-items: center;
					.acao {
						padding: 10px;
						.fa {
							color: $gray;
						}
						&:hover {
							background-color: #cccccc;
							.fa {
								color: $dark;
							}
						}
					}
				}
			}

			&.even {
				background-color: $gray-lighter;
			}

			&.odd {
				background-color: $gray-light;
			}
		}
	}
	thead {
		tr {
			background-color: $gray-light;
			th {
				white-space: nowrap;
				padding: 10px;
			}
			.th-style,
			.td-style {
				border: none;
				border-bottom: 2px solid $light;
				border-right: 2px solid $light;
			}
		}
	}
	.th-style,
	.td-style {
		border: none;
		border-right: 2px solid $light;
	}
}

.table-adquirentes {
	background-color: $gray-lighter;
	border: none;
	border-spacing: 0;
	width: 100%;
	tbody {
		tr {
			td {
				// white-space: nowrap;
				padding: 10px;

				&.acoes {
					display: flex;
					justify-content: center;
					align-items: center;
					.acao {
						padding: 10px;
						.fa {
							color: $gray;
						}
						&:hover {
							background-color: #cccccc;
							.fa {
								color: $dark;
							}
						}
					}
				}
			}

			&.even {
				background-color: $gray-lighter;
			}

			&.odd {
				background-color: $gray-light;
			}
		}
	}
	thead {
		tr {
			background-color: $gray-light;
			th {
				// white-space: nowrap;
				padding: 10px 0;
			}
		}
	}
}

.th-style,
.td-style {
	border: 1px solid #ddd;
	border-spacing: 0;
	text-align: center;
	padding: 8px;
	margin: 0;
}

.dados-adquirente.form-group {
	.input-group {
		margin-bottom: 0;
	}
	.card-body {
		padding: 0.8rem 1.25rem;

		&:last-child {
			margin-bottom: 1rem;
		}
	}

	.card-title {
		margin-bottom: 0;
	}
}

.table-adquirentes-nome,
.table-adquirentes-cpf,
.table-adquirentes-data-leilao,
.table-adquirentes-numero-leilao {
	text-align: left;
}

.table-adquirentes-cpf {
	min-width: 200px;
}

.dados-leilao.form-group {
	.input-group {
		margin-bottom: 24px;
	}
	.card {
		margin-bottom: 20px;
	}
	.card-title {
		margin-bottom: 0;
	}
}

.input-transf {
	display: flex;
	flex: 1;
	border: 1px solid $gray;
	padding: 8px 15px;
	justify-content: right;
	position: relative;
	min-width: 100%;

	label {
		position: absolute;
		top: -20px;
		right: 14px;
		font-size: 0.9rem;
		color: $gray;
	}

	&.left {
		justify-content: left;
		label {
			right: auto;
			left: 13px;
		}
	}
}

/*.tabela-solo-criado-detalhe {
	border: 2px solid #687c8163;
	padding: 7px;
}*/

.celulas-tabela-solo-criado-detalhe {
	padding: 8px;
	border: none;
	border-spacing: 0px;
	align-content: center;
}

.celulas-tabela-solo-criado-edicao {
	// border-bottom: 1px solid #687c8163;
	padding-bottom: 8px;
	border-top: 1px solid #687c8163;
	padding-top: 8px;
}

.input-etapa-requerimento,
.input-adquirente {
	.title-with-button {
		border: 1px solid #687c80;
		padding: 8px 15px;
	}
}

.conteudo-solo-criado {
	.input-like label {
		left: 0;
	}
}

.btn-solo-criado {
	font-size: 14px;

	i {
		margin-right: 10px;
	}
}

.tabela-solo-criado-edicao {
	padding: 7px;
	background-color: rgb(237, 239, 241);
}

.celulas-tabela-solo-criado-edicao {
	font-size: 14px;
}

.celulas-tabela-solo-criado-edicao-icones {
	font-size: 16px;
}

.btn-consulta-alvara {
	text-decoration: underline;
	text-transform: uppercase;
	font-size: 0.8em;
	font-weight: 500;
	letter-spacing: 0.03em;
}

.linha-detalhe-endereco {
	border-bottom: 1px solid #dee2e5;
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;

	.fa-trash {
		font-size: 1em !important;

		&:hover {
			color: #fa970f !important;
		}
	}
}

li.embed-list-item:last-child .linha-detalhe-endereco {
	border-bottom: 0;
}

.icon-consulta-alvara {
	margin-left: 0.3rem;
}

.tip-alvaras {
	color: #6c7c7f;
	font-size: 0.8em;
	display: flex;
	justify-content: right;
	align-items: center;
	// font-weight: bold;
	margin-top: -20px;

	&:before {
		content: '\f0eb';
		font-family: FontAwesome;
		color: #eec32a;
		font-size: 19px;
		font-weight: normal;
		margin-right: 7px;
	}
}

.anexos-slots-minio {
	.btn-excluir {
		background-color: transparent;
		border: 0;
		color: #dc3545;
		margin-right: 2rem;
		float: right;
		font-size: 1.2em;
		margin-top: -33px;
		cursor: pointer;
	}
	.linha-upload {
		margin-top: 6px;
		border-bottom: 2px solid #e4e4e4;
		padding: 8px 16px;
	}
	.icon-tooltip a {
		color: #fa9711;
		text-decoration: underline;
		font-family: 'Lato', sans-serif;
		font-weight: bold;
		float: left;
		margin-left: 30px;
		margin-top: -16px;
	}
	.icone-upload-pdf {
		font-size: 18px;
		float: left;
		margin-right: 0.5rem;
	}

	.btn-add-doc-minio {
		margin-top: 0.7rem;
		&:before {
			content: '\F067';
			font-family: 'FontAwesome';
			margin-right: 0.2rem;
		}
	}
	.fa-check:before {
		content: '\f00c';
		color: #32bb99;
	}
}

.body-modal-minio {
	margin-top: 6px;
	// border: 2px solid #c5c5c9;
	padding: 16px;

	i.fa.fa-file-pdf-o {
		margin-right: 0.5rem;
	}

	.icon-tooltip {
		font-family: 'Lato', sans-serif;
	}

	.titulo-arquivo {
		font-weight: bold;
		margin-top: 1rem;
	}
}

.btn-danger {
	color: #dc3545;
}

.label-titulo-doc-add {
	// margin-top: 2rem;
	font-size: 1.05em !important;
	margin-left: 14px;
	font-weight: bold !important;
}

.content-consulta-por-adquirente {
	.input-group {
		padding: 0 32px;
	}

	.titulo-principal-consulta-por-adquirente {
		font-size: 28px;
		color: #90a1a5;
		font-weight: bold;
		margin-top: 30px;
		margin-left: 32px;
	}

	.titulo-secundario-consulta-por-adquirente,
	.titulo-secundario-consulta-por-adquirente-aux {
		color: #242a2b;
		font-weight: bold;
		margin-left: 32px;
	}
	.titulo-secundario-consulta-por-adquirente {
		&:before {
			content: 'CPF:';
			color: #90a1a5;
			font-weight: normal;
			margin-right: 6px;
		}
	}
	.titulo-secundario-consulta-por-adquirente-aux {
		span {
			content: 'CPF:';
			color: #90a1a5;
			font-weight: normal;
			margin-right: 6px;
		}
	}
}

.cabecalho-consulta-por-adquirente {
	display: flex;
	justify-content: space-between;
	width: 100%;

	.btn {
		font-family: 'Lato', sans-serif;
		font-size: 14px;
		cursor: pointer !important;
		margin-right: 32px;
		margin-top: 32px;
	}

	i {
		margin-right: 10px;
	}
}

.conteudo-consulta-por-adquirente {
	margin-top: 32px;
}

.linha-leilao {
	flex: 1;
}

.leilao-efetivado {
	margin-top: 20px;
	font-weight: bold;
}

.custom-card-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px; /* Ajuste conforme necessário */
}

.custom-card-item-right {
	margin-left: auto;
}

.logo-prefeitura {
	display: none;
}

.background-image {
	display: none;
}

.invisivel {
	visibility: hidden;
}

/* IMPRESSÃO */
@media print {
	@page {
		margin: 15mm 10mm 15mm 10mm;
	}

	.app-header {
		margin-top: 20px;
		background-color: transparent;
	}

	.header-button {
		color: #242a2b;
	}

	.content-block {
		margin-top: 30px;
	}

	.copy-button,
	.btn,
	.fa,
	.breadcrumbs,
	.submit-group {
		display: none;
	}

	.logo-prefeitura {
		display: block;
		background-color: transparent;
		border: none;
		padding-right: 5mm;

		img {
			width: 30mm;
		}
	}

	.background-image {
		display: block;
		width: 600;
		opacity: 0.1;
		position: fixed;
		padding: 90mm 70mm;
	}

	.empty-embed-list,
	.embed-list,
	.embed-list-item,
	.grid {
		background-color: transparent !important;
		border-bottom: none !important;
	}
}

.toolbar-multiline {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	gap: 10px;
	margin: 10px 0px;
}

.button-change {
	border: 0;
	background-color: transparent;
	&:hover {
		background-color: buttonface;
	}
}

.selecao-flex {
	display: flex;
	gap: 10px;
	border: 1px solid black;
	padding: 8px 16px;
	align-items: baseline;
}

.grid-label-valor {
	display: grid;
	grid-template-columns: 1fr 0.8fr;
	gap: 10px;

	&.columns {
		grid-template-columns: 100px 1fr;
	}

	.grid-label {
		color: $gray;
		white-space: nowrap;
	}

	.grid-valor {
		white-space: nowrap;
	}
}

.tippy-content {
	padding: 5px 15px 10px 15px;
}

.table-detalhe-projeto {
	text-align: right;

	thead {
		tr {
			background-color: $gray-light;
		}
		th {
			vertical-align: middle;
		}
	}
}

.checkboxes-list {
	position: relative;

	/* Hide the browser's default checkbox */
	.checkbox-group input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		// transform: scale(1.5);
		// margin-right: 12px;
	}

	.label {
		margin-left: 30px;
	}

	/* Create a custom checkbox */
	.checkbox-group .checkmark {
		position: absolute;
		top: -6px;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eee;
		border: 2px solid $gray-dark;
		border-radius: 0.3rem;
	}

	/* On mouse-over, add a grey background color */
	.checkbox-group:hover input ~ .checkmark {
		background-color: #ddd;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkbox-group .checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.checkbox-group input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.checkbox-group .checkmark:after {
		left: 5px;
		top: 0px;
		width: 8px;
		height: 12px;
		border: solid $gray-dark;
		border-width: 0px 4px 4px 0;
		transform: rotate(45deg);
	}
}

.item-condominio {
	margin-right: 12px;
	width: 300px;
	flex-grow: 1;
}

.condominio-data-despacho {
	max-width: 280px;
}
