.selos {
	position: absolute;
	top: -61px;
	right: 0px;

	@media (max-width: 900px) {
		right: 0px;
	}

	.selo {
		width: 100px;
		float: right;
		margin-left: 17px;
		position: relative;
		@media (max-width: 550px) {
			width: 75px;
			margin-left: 5px;
		}
		img {
			box-shadow: 7px 4px 7px rgba(0, 0, 0, 0.2);
			width: 100%;
		}
	}
}

.selos_lateral {
	position: relative;
	float: right;
	width: 100px;
	right: -29px;
	top: -5px;
	@media (max-width: 550px) {
		width: 75px;
	}
	.selo_lateral {
		position: relative;
		float: left;

		img {
			width: 100px;
			@media (max-width: 550px) {
				width: 75px;
			}
			-webkit-box-shadow: 7px 4px 7px rgba(0, 0, 0, 0.2);
			box-shadow: 7px 6px 7px rgba(0, 0, 0, 0.2);
		}
	}
}
